@use 'styles/base/variables';
@use 'styles/base/mixins';

.headline {
    color: variables.$gb_white;
    &.light {
        color: variables.$gb_black;
    }
}


.copyText {
    margin-top: 0.75rem;
    @include mixins.typography-body-1();

    @include mixins.screen-xs {
        @include mixins.line-clamp(3);
    }

    @media screen and (width: variables.$gb_breakpointMD) {
        @include mixins.line-clamp(1);

    }

    @include mixins.screen-lg {
        @include mixins.line-clamp(2);
    }
}

.link {
    span {
        @include mixins.typography-label-1();
    }
}