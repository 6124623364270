@use 'styles/base/variables';
@use 'styles/base/mixins';

.headlineAndSublineUppercase {
  text-transform: uppercase;
}

.twyfordHeadlineAndSubline {
  /* Title. */
  span {
    font-weight: var(--font-weight-light);
  }

  /* Subtitle (Bold medium). */
  span + span {
    font-weight: var(--font-weight-medium);
    span {
      font-weight: var(--font-weight-medium);
    }
  }
}
